var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
export var ActionType;
(function (ActionType) {
    ActionType["loading"] = "sending";
    ActionType["setlist"] = "setlist";
    ActionType["setError"] = "setError";
})(ActionType || (ActionType = {}));
export var initialState = {
    loading: false,
    listDetail: [],
    error: false
};
var statusReduce = (_a = {},
    _a[ActionType.loading] = function (state, value) { return (__assign(__assign({}, state), { loading: value, error: false })); },
    _a[ActionType.setlist] = function (state, value) { return (__assign(__assign({}, state), { loading: false, listDetail: value })); },
    _a[ActionType.setError] = function (state) { return (__assign(__assign({}, state), { loading: false, error: true })); },
    _a);
export var reducer = function (state, action) {
    return statusReduce[action.type](state, action.value);
};
