var _a;
export var EnumDay;
(function (EnumDay) {
    EnumDay[EnumDay["Domingo"] = 0] = "Domingo";
    EnumDay[EnumDay["Lunes"] = 1] = "Lunes";
    EnumDay[EnumDay["Martes"] = 2] = "Martes";
    EnumDay[EnumDay["Miercoles"] = 3] = "Miercoles";
    EnumDay[EnumDay["Jueves"] = 4] = "Jueves";
    EnumDay[EnumDay["Viernes"] = 5] = "Viernes";
    EnumDay[EnumDay["Sabado"] = 6] = "Sabado";
})(EnumDay || (EnumDay = {}));
export var TypeFile;
(function (TypeFile) {
    TypeFile["PDF"] = "PDF";
    TypeFile["HTML"] = "HTML";
})(TypeFile || (TypeFile = {}));
export var CodeError;
(function (CodeError) {
    CodeError[CodeError["default"] = 4000] = "default";
    CodeError[CodeError["schedulerAreCrossed"] = 4001] = "schedulerAreCrossed";
    CodeError[CodeError["errorHourLoad"] = 4002] = "errorHourLoad";
    CodeError[CodeError["errorDelete"] = 4003] = "errorDelete";
    CodeError[CodeError["errorLogin"] = 4004] = "errorLogin";
})(CodeError || (CodeError = {}));
export var MAP_DAY_NAME = (_a = {},
    _a[EnumDay.Lunes] = "Lunes",
    _a[EnumDay.Martes] = "Martes",
    _a[EnumDay.Miercoles] = "Miercoles",
    _a[EnumDay.Jueves] = "Jueves",
    _a[EnumDay.Viernes] = "Viernes",
    _a[EnumDay.Sabado] = "Sabado",
    _a[EnumDay.Domingo] = "Domingo",
    _a);
