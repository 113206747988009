import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
export var HeaderTable = function (_a) {
    var children = _a.children, title = _a.title;
    return React.createElement(Container, { fluid: true },
        React.createElement(Row, null,
            React.createElement(Col, null,
                React.createElement("h1", null, title)),
            children && React.createElement(Col, { xs: "auto" },
                " ",
                children)));
};
