var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useAuth } from './useLogin';
import { Message } from '../../components/Message';
import './Login.scss';
import { MAP_ERROS, windowProps } from "../../contract";
import { CodeError } from "../../../commons";
export var Login = function () {
    var _a = useAuth(), errors = _a.errors, handleSubmit = _a.handleSubmit, error = _a.error, sending = _a.sending, register = _a.register;
    return React.createElement("div", { className: "Login" },
        React.createElement("div", { className: "Login__content" },
            React.createElement("h1", { style: { textAlign: 'center', marginBottom: '40px' } }, windowProps.nameCompany),
            React.createElement(Form, { onSubmit: handleSubmit },
                React.createElement(Form.Group, null,
                    React.createElement(Form.Label, null, "Usuario"),
                    React.createElement(Form.Control, __assign({}, register("user", {
                        required: { value: true, message: 'Campo requerido' }
                    }))),
                    errors.user && React.createElement(Message, { error: true }, errors.user.message)),
                React.createElement("br", null),
                React.createElement(Form.Group, null,
                    React.createElement(Form.Label, null, "Passs"),
                    React.createElement(Form.Control, __assign({}, register("pass", {
                        required: { value: true, message: 'Campo requerido' }
                    }), { type: "password" })),
                    errors.pass && React.createElement(Message, { error: true }, errors.pass.message)),
                React.createElement("br", null),
                error && React.createElement(Message, { error: true }, MAP_ERROS[CodeError.errorLogin]),
                React.createElement(Button, { disabled: sending, type: "submit" }, "Ingresar"))));
};
