var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useCreateMenu } from './useCreateMenu';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { OPTION_HOUR, OPTION_MIN } from '../../../../contract';
import { isFileAllowed } from '../../../../utils/validate';
import { Message } from '../../../../components/Message';
export var CreateMenu = function (_a) {
    var refreshTable = _a.refreshTable;
    var _b = useCreateMenu(refreshTable), isOpen = _b.isOpen, toogleModal = _b.toogleModal, createMenu = _b.createMenu, register = _b.register, errors = _b.errors, success = _b.success, error = _b.error, sending = _b.sending;
    return React.createElement(React.Fragment, null,
        React.createElement(Button, { onClick: toogleModal }, "Agregar Menu"),
        React.createElement(Modal, { show: isOpen, onHide: toogleModal, backdrop: "static", keyboard: false },
            React.createElement(Modal.Header, { closeButton: true },
                React.createElement(Modal.Title, null, "Crear Nuevo Menu")),
            React.createElement(Modal.Body, null,
                React.createElement(Form, { onSubmit: createMenu },
                    React.createElement(Form.Group, null,
                        React.createElement(Form.Label, null, "Nombre del menu"),
                        React.createElement(Form.Control, __assign({}, register("nameScheduler", {
                            required: { value: true, message: 'Campo requerido' }
                        }), { placeholder: "Nombre de menu" })),
                        (errors === null || errors === void 0 ? void 0 : errors.nameScheduler) &&
                            React.createElement("p", { className: 'invalid-feedback', style: { display: 'block' } }, errors.nameScheduler.message)),
                    React.createElement("br", null),
                    React.createElement(Form.Group, null,
                        React.createElement(Form.Label, null, "Inicio"),
                        React.createElement(Row, null,
                            React.createElement(Col, null,
                                React.createElement(Form.Select, __assign({}, register('hourStart', {
                                    required: { value: true, message: 'Campo requerido' }
                                }), { size: "sm" }),
                                    React.createElement("option", { value: "" }, "hora"),
                                    OPTION_HOUR.map(function (item) { return (React.createElement("option", { key: item.value, value: item.value }, item.label)); })),
                                (errors === null || errors === void 0 ? void 0 : errors.hourStart) &&
                                    React.createElement("p", { className: 'invalid-feedback', style: { display: 'block' } }, errors.hourStart.message)),
                            React.createElement(Col, null,
                                React.createElement(Form.Select, __assign({}, register('minStart', {
                                    required: { value: true, message: 'Campo requerido' }
                                }), { size: "sm" }),
                                    React.createElement("option", { value: "" }, "minuto"),
                                    OPTION_MIN.map(function (item) { return (React.createElement("option", { key: item.value, value: item.value }, item.label)); })),
                                (errors === null || errors === void 0 ? void 0 : errors.minStart) &&
                                    React.createElement("p", { className: 'invalid-feedback', style: { display: 'block' } }, errors.minStart.message)))),
                    React.createElement("br", null),
                    React.createElement(Form.Group, null,
                        React.createElement(Form.Label, null, "Fin"),
                        React.createElement(Row, null,
                            React.createElement(Col, null,
                                React.createElement(Form.Select, __assign({}, register('hourEnd', {
                                    required: { value: true, message: 'Campo requerido' }
                                }), { size: "sm" }),
                                    React.createElement("option", { value: "" }, "hora"),
                                    OPTION_HOUR.map(function (item) { return (React.createElement("option", { key: item.value, value: item.value }, item.label)); })),
                                (errors === null || errors === void 0 ? void 0 : errors.hourEnd) &&
                                    React.createElement("p", { className: 'invalid-feedback', style: { display: 'block' } }, errors.hourEnd.message)),
                            React.createElement(Col, null,
                                React.createElement(Form.Select, __assign({}, register('minEnd', {
                                    required: { value: true, message: 'Campo requerido' }
                                }), { size: "sm" }),
                                    React.createElement("option", { value: "" }, "minuto"),
                                    OPTION_MIN.map(function (item) { return (React.createElement("option", { key: item.value, value: item.value }, item.label)); })),
                                (errors === null || errors === void 0 ? void 0 : errors.minEnd) &&
                                    React.createElement("p", { className: 'invalid-feedback', style: { display: 'block' } }, errors.minEnd.message)))),
                    React.createElement("br", null),
                    React.createElement(Form.Control, __assign({}, register('file', {
                        required: { value: true, message: 'Campo requerido' },
                        validate: function (data) {
                            return isFileAllowed(data) || "archivo invalido";
                        }
                    }), { type: 'file' })),
                    (errors === null || errors === void 0 ? void 0 : errors.file) &&
                        React.createElement("p", { className: 'invalid-feedback', style: { display: 'block' } }, errors.file.message))),
            React.createElement(Modal.Footer, null,
                error ? React.createElement(Message, { error: true }, error) : null,
                success ? React.createElement(Message, null, "Se agrego correctamente") : null,
                React.createElement(Button, { disabled: sending, onClick: createMenu }, "Agregar Menu"))));
};
