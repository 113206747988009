import React from 'react';
import { HeaderTable } from '../../components/HeaderTable';
import Table from 'react-bootstrap/Table';
import { useGetDays } from './useGetDays';
import Spinner from 'react-bootstrap/esm/Spinner';
import { MAP_ERROS, RoutesApp } from '../../contract';
import { CodeError } from '../../../commons';
import { Link } from 'react-router-dom';
export var Day = function () {
    var _a = useGetDays(), daysList = _a.daysList, loading = _a.loading, error = _a.error, selectDefaultDay = _a.selectDefaultDay;
    if (error) {
        return React.createElement("h1", null, MAP_ERROS[CodeError.default]);
    }
    if (loading) {
        return React.createElement(Spinner, { animation: "border", role: "status" },
            React.createElement("span", { className: "visually-hidden" }, "Loading..."));
    }
    return React.createElement("div", { style: { padding: '40px' } },
        React.createElement(HeaderTable, { title: 'Detalle de Dias' }),
        React.createElement("br", null),
        React.createElement(Table, { striped: true, bordered: true, hover: true },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", null, "Dia"),
                    React.createElement("th", null, "Cantidad de menus cargados"),
                    React.createElement("th", null, "Dia default"),
                    React.createElement("th", null))),
            React.createElement("tbody", null, daysList.map(function (item, index) { return (React.createElement("tr", { key: index },
                React.createElement("td", null, item.name),
                React.createElement("td", null, item.count),
                React.createElement("td", { align: 'center' },
                    React.createElement("input", { className: 'form-check-input', type: 'checkbox', checked: item.isDefault, onChange: function () { return selectDefaultDay(item.numberDay); } })),
                React.createElement("td", { align: 'center' },
                    React.createElement(Link, { to: RoutesApp.DayDetail.replace(':numberDay', item.numberDay), className: 'btn btn-primary' }, "Detalle")))); }))));
};
