import React from 'react';
import { Router } from './router';
import { BrowserRouter } from 'react-router-dom';
import { useAuth } from './hook/userAuth';
import { Loading } from './components/Loading';
import { Login } from './pages/Login';
import { Header } from './components/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import { windowProps } from './contract';
export var App = function () {
    var _a = useAuth(), loading = _a.loading, isLogin = _a.isLogin;
    if (loading) {
        return React.createElement(Loading, null);
    }
    if (!isLogin) {
        return React.createElement(Login, null);
    }
    return React.createElement(React.Fragment, null,
        React.createElement(Header, null),
        React.createElement(BrowserRouter, { basename: windowProps.basePath },
            React.createElement(Router, null)));
};
