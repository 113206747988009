import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { CodeError } from '../../../../../commons';
import { Message } from '../../../../components/Message';
import { MAP_ERROS } from '../../../../contract';
import { useDeleteMenu } from './useDeleteMenu';
export var DeleteMenu = function (_a) {
    var refreshTable = _a.refreshTable, rango = _a.rango, idScheduler = _a.idScheduler;
    var _b = useDeleteMenu(refreshTable), showModal = _b.showModal, toogleModal = _b.toogleModal, deleteMenu = _b.deleteMenu, loading = _b.loading, deleteError = _b.deleteError, deleteSuccess = _b.deleteSuccess;
    return React.createElement(React.Fragment, null,
        React.createElement(Button, { onClick: toogleModal }, "Eliminar"),
        React.createElement(Modal, { show: showModal, onHide: toogleModal, backdrop: "static", keyboard: false },
            React.createElement(Modal.Header, { closeButton: true },
                React.createElement(Modal.Title, null, "Eliminar Horario")),
            React.createElement(Modal.Body, null,
                React.createElement("h4", null,
                    "\u00BFEsta seguro de Eliminar el Horario ",
                    React.createElement("b", null, rango),
                    "?")),
            React.createElement(Modal.Footer, null,
                deleteError && React.createElement(Message, { error: true }, MAP_ERROS[CodeError.errorDelete]),
                deleteSuccess && React.createElement(Message, null, "Se elimino correctamente"),
                !deleteSuccess && React.createElement(Button, { disabled: loading, onClick: function () { return deleteMenu(idScheduler); } }, "Borrar"))));
};
