var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
export var ActionType;
(function (ActionType) {
    ActionType["Setloading"] = "Setloading";
    ActionType["SetSuccessDelete"] = "SetSuccessDelete";
    ActionType["SetErrorDelete"] = "SetErrorDelete";
    ActionType["SetModalShow"] = "SetModalShow";
})(ActionType || (ActionType = {}));
export var initialState = {
    loading: false,
    deleteError: false,
    deleteSuccess: false,
    showModal: false
};
var statusReduce = (_a = {},
    _a[ActionType.SetSuccessDelete] = function (state, value) { return (__assign(__assign({}, state), { loading: false, deleteSuccess: true })); },
    _a[ActionType.Setloading] = function (state, value) { return (__assign(__assign({}, state), { loading: value })); },
    _a[ActionType.SetErrorDelete] = function (state) { return (__assign(__assign({}, state), { loading: false, deleteError: true })); },
    _a[ActionType.SetModalShow] = function (state, value) { return (__assign(__assign(__assign({}, state), initialState), { showModal: value })); },
    _a);
export var reducer = function (state, action) {
    return statusReduce[action.type](state, action.value);
};
