var _a;
import { CodeError } from "../../commons";
export var windowProps = window.PROPS_REACT;
export var RoutesApp;
(function (RoutesApp) {
    RoutesApp["DayDetail"] = "/day-detail/:numberDay";
    RoutesApp["Days"] = "/";
    RoutesApp["NotFount"] = "*";
})(RoutesApp || (RoutesApp = {}));
export var OPTION_HOUR = Array(24).fill(0).map(function (_value, index) {
    return {
        value: index,
        label: index < 10 ? "0".concat(index) : "".concat(index)
    };
});
export var OPTION_MIN = Array(60).fill(0).map(function (_value, index) {
    return {
        value: index,
        label: index < 10 ? "0".concat(index) : "".concat(index)
    };
});
export var MAP_ERROS = (_a = {},
    _a[CodeError.schedulerAreCrossed] = "Verificar los horarios que se esta pisando con otro",
    _a[CodeError.default] = "Hubo un error intente de nuevo mas tarde",
    _a[CodeError.errorHourLoad] = "La hora de Fin tiene que ser mayor a Inicial",
    _a[CodeError.errorDelete] = "Hubo un error al intentar eliminar intente mas tarde",
    _a[CodeError.errorLogin] = "El usuario o la contraseña es incorrecta",
    _a);
