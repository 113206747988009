import { request } from '../utils/request';
import { windowProps } from '../contract';
export var logInApi = function (user, pass) {
    return request("".concat(windowProps.basePath, "/api/login"), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ user: user, pass: pass })
    });
};
export var verify = function () { return request("".concat(windowProps.basePath, "/api/login/verify"), {
    method: 'POST'
}); };
