import { request } from '../utils/request';
import { windowProps } from '../contract';
var basePath = windowProps.basePath;
export var createMenuApi = function (body) { return request("".concat(basePath, "/api/menu"), {
    method: 'POST',
    body: body
}); };
export var getMenuByDay = function (numberDay) { return request("".concat(basePath, "/api/menu/").concat(numberDay)); };
export var deleteMenuByDayApi = function (numberDay, idScheduler) { return request("".concat(basePath, "/api/menu/").concat(numberDay), {
    method: 'DELETE',
    headers: {
        'Content-Type': 'application/json'
    },
    body: JSON.stringify({ idScheduler: idScheduler })
}); };
export var getDaysAPi = function () { return request("".concat(basePath, "/api/menu")); };
export var selectDayDefaultAPi = function (numberDay) {
    return request("".concat(basePath, "/api/menu/default/").concat(numberDay), {
        method: 'POST'
    });
};
