import React from 'react';
import Table from 'react-bootstrap/Table';
import { HeaderTable } from '../../components/HeaderTable';
import { CreateMenu } from './components/CreateMenu';
import { useGetDetailDay } from './useGetMenu';
import Spinner from 'react-bootstrap/Spinner';
import { DeleteMenu } from './components/DeteleMenu';
export var DayDetail = function () {
    var _a = useGetDetailDay(), getDetailDay = _a.getDetailDay, listDetail = _a.listDetail, loading = _a.loading, getDayName = _a.getDayName;
    if (loading) {
        return React.createElement(Spinner, { animation: "border", role: "status" },
            React.createElement("span", { className: "visually-hidden" }, "Loading..."));
    }
    return React.createElement("div", { style: { padding: '40px' } },
        React.createElement(HeaderTable, { title: getDayName() },
            React.createElement(CreateMenu, { refreshTable: getDetailDay })),
        React.createElement("br", null),
        React.createElement(Table, { striped: true, bordered: true, hover: true },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", null, "Rango de horario"),
                    React.createElement("th", null, "Nombre del menu"),
                    React.createElement("th", null, "File"),
                    React.createElement("th", null))),
            React.createElement("tbody", null, listDetail.map(function (item, index) { return (React.createElement("tr", { key: index },
                React.createElement("td", null, item.range),
                React.createElement("td", null, item.nameMenu),
                React.createElement("td", null,
                    React.createElement("a", { href: item.dirFile, download: true }, item.fileName)),
                React.createElement("td", { align: 'center' },
                    React.createElement(DeleteMenu, { refreshTable: getDetailDay, idScheduler: item.idScheduler, rango: item.range })))); }))));
};
