import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { RoutesApp } from '../contract';
import { DayDetail } from '../pages/DayDetail';
import { Day } from '../pages/Day';
import { Page404 } from '../pages/404';
export var Router = function () {
    return React.createElement(Routes, null,
        React.createElement(Route, { path: RoutesApp.DayDetail, element: React.createElement(DayDetail, null) }),
        React.createElement(Route, { path: RoutesApp.Days, element: React.createElement(Day, null) }),
        React.createElement(Route, { path: RoutesApp.NotFount, element: React.createElement(Page404, null) }));
};
